import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Card, ResourceList, Avatar, ResourceItem, TextStyle, Stack } from "@shopify/polaris";
import * as firebase from 'firebase/app';
import { getShopRewardsRef } from '../../helpers/database';
import { Reward, Nullable } from '../../helpers/types';
import { Context, Modal } from '@shopify/app-bridge-react';
import { Modal as ModalAction, Button } from '@shopify/app-bridge/actions';
import { default as ErrorBanner } from "../../templates/errors/error-banner";
import { Redirect, History } from '@shopify/app-bridge/actions';
import { navigateAndPushHistory } from '../../helpers/navigation';

const component: React.FC<{
    pointLimit?: number,
    resultLimit?: number,
    shop: string,
    token: string;
    customerId: string;
    firebase: firebase.app.App
}> = props => {
    const context = useContext(Context)
    const { pointLimit = 1000, resultLimit = 10, shop, firebase, token, customerId } = props
    if (!shop || !firebase || !context) {
        return <ErrorBanner errorCode="error-getting-props" />
    }
    const redirect = Redirect.create(context)
    const history = History.create(context)
    const rewardsRef = getShopRewardsRef(firebase, shop);
    // const [isLoading, setIsLoading] = useState([{}] as Reward[])
    const [rewards, setRewards] = useState([{}] as Reward[])
    const [selectedReward, setSelectedReward] = useState(null as Nullable<string>)
    const [modalSaving, setModalSaving] = useState(false)
    const [query, setQuery] = useState(
        rewardsRef
            .orderBy("points", "desc")
            .orderBy("name", "asc")
            .limit(resultLimit)
    )

    const resetModel = useCallback(() => {
        setModalSaving(false);
        setSelectedReward(null);
    }, [setModalSaving, setSelectedReward])
    const [permissionNeededUrl, setPermissionNeededUrl] = useState(null as Nullable<string>)




    useEffect(() => {
        const unsubscribe = query.onSnapshot({
            next: querySnap => {
                const c = querySnap.docs.map(docSnap => ({
                    id: docSnap.id,
                    ...docSnap.data() as Reward,
                }))
                setRewards(c);
                console.log(c);
                // setIsLoading(false);
            },
            error: e => {
                const { message } = e
                if (message && message.trim().slice(0, 10) === "false for ") {
                    //   setError("db-permission-denied")
                } else {
                    console.error(e)
                    //   setError(true)
                }
                // setIsLoading(false)
            }
        })
        return unsubscribe
    }, [query])


    return (
        <Card title="Available rewards" actions={[{
            content: 'Add reward',
            // url: '/app/rewards/new',
            onAction: () => navigateAndPushHistory('/app/rewards/new', context)
        }]}>
            <Modal title="Issue new reward"
                message="Are you sure"
                open={!!selectedReward}
                onClose={() => resetModel()}
                primaryAction={{
                    content: 'Issue',
                    disabled: modalSaving,
                    onAction: async () => {
                        if (!selectedReward) return;
                        console.log('Issue')
                        setModalSaving(true);
                        const result = await callIssueReward({
                            accessToken: token,
                            shop,
                            customerId,
                            rewardId: selectedReward
                        });
                        console.log({ result });
                        resetModel();
                        const { data: resultData, success } = result.data;
                        const { url } = resultData;
                        if (!success && url) {
                            const okButton = Button.create(context, {label: 'Ok'});
                            const cancelButton = Button.create(context, {label: 'Cancel'});
                            okButton.subscribe(Button.Action.CLICK, data => {
                                permModal.dispatch(ModalAction.Action.CLOSE);
                                redirect.dispatch(Redirect.Action.REMOTE, url);
                            })
                            cancelButton.subscribe(Button.Action.CLICK, data => {
                                permModal.dispatch(ModalAction.Action.CLOSE);
                            })
                            const permModal = ModalAction.create(context, {
                                title: 'My Modal',
                                message: 'Hello world!',
                                footer: {
                                    buttons: {
                                        primary: okButton,
                                        secondary: [cancelButton]
                                    }
                                }
                            })
                            permModal.dispatch(ModalAction.Action.OPEN);

                        }
                        // setShowApplyModel(false);
                        // setModalSaving(false);
                    }
                }}
                secondaryActions={[{
                    content: 'Cancel',
                    onAction: resetModel
                }]}
            />
            <ResourceList
                resourceName={{ singular: 'reward', plural: 'rewards' }}
                items={rewards}
                renderItem={(item) => {
                    const { id, name, points } = item;
                    const media = <Avatar customer={false} size="medium" name={name} />
                    const url = `/app/rewards/edit?id=${id}`
                    const detailUrl = url;

                    return (
                        <ResourceItem
                            id={id}
                            onClick={() => {
                                // redirect.dispatch(Redirect.Action.APP, url);
                                // history.dispatch(History.Action.PUSH, url);
                                setSelectedReward(id);

                            }}
                            // url={url}
                            media={media}
                            accessibilityLabel={`Open reward dialog for ${name}`}
                            shortcutActions={[{
                                content: 'Edit reward',
                                // url: detailUrl,
                                onAction: () => navigateAndPushHistory(url, context)
                            }]}
                        >
                            <Stack distribution="fill" alignment="center">
                                <h3> <TextStyle variation="strong">{name}</TextStyle>
                                </h3>
                                <div>{points} points</div>
                            </Stack>
                        </ResourceItem>
                    );
                }}
            />
        </Card>
    )

}
const callIssueReward = (params: {
    accessToken: string,
    shop: string,
    customerId: string,
    rewardId: string,
}) => {
    return firebase.functions().httpsCallable('callIssueReward')(params)
}

export default component;