import React, { useState, useContext, useEffect } from "react"
import { AppProps } from "../../../helpers/app-props"
import { default as loadingTemplate } from "../../../templates/loading/customers-index"
import { default as CustomerCard } from "../../../templates/customer/customer-card";
import { default as MessageCard } from "../../../templates/customer/message.card";
import { default as PointsModal } from "../../../templates/customer/adjust-points.modal"
import { default as AvailableRewarads } from "../../../templates/customer/available.rewards"
import { PermissionRedirectModal } from "../../../templates/modals"
import { Context } from "@shopify/app-bridge-react"
import { withFirebase } from "../../../providers/firebase"
import {
  Page,
  Layout,
  Badge,
  Banner,
  FooterHelp,
  Link,
} from "@shopify/polaris"
import { CustomerWithId, Nullable } from "../../../helpers/types"
import { setTitleBar } from "../../../helpers/actions"
import {
  getShopCustomersRef,
  adjustCustomerPoints,
} from "../../../helpers/database"
import { default as ErrorBanner } from "../../../templates/errors/error-banner"
import { useQueryString } from "../../../helpers/hooks"
import { Redirect, Toast, History } from "@shopify/app-bridge/actions"
import {
  CustomersMajorMonotone,
  EditMinor,
} from "@shopify/polaris-icons"
import { navigate } from "gatsby";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { ClaimedRewards } from "../../../templates/customer/claimed.rewards";

const acceptsMarketing = (customer: CustomerWithId) => {
  return true;
}

const PrettyPrintJson = ({ data }: { data: object }) => (<div><pre>{JSON.stringify(data, null, 2)}</pre></div>);

const CustomerDetail: React.FC<AppProps> = props => {
  const context = useContext(Context)
  const { firebase, shop, token } = props

  if (!firebase || !shop || !context) {
    return <ErrorBanner errorCode="error-getting-props" />
  }
  const redirect = Redirect.create(context)
  const history = History.create(context)
  const [error, setError] = useState(false as boolean | string)
  const [isLoading, setIsLoading] = useState(true)
  const [showPointModal, setShowPointModal] = useState(false)
  const [customerId] = useQueryString("id")
  const [customer, setCustomer] = useState({} as CustomerWithId)
  const [smsMessage, setSmsMessage] = useState(false)
  // const [permissionNeededUrl, setPermissionNeededUrl] = useState(null as Nullable<string>)

  // const { data: gqlData } = useQuery(GET_SHOP_INFO)

  // Message configuration
  const prefix = "DaKine: ";
  const suffix = " Reply HELP for help, STOP to cancel";
  const maxLength = 160;


  useEffect(() => {
    console.error("LOADING detail")
    if (!customerId) return
    const unsubscribe = getShopCustomersRef(firebase, shop)
      .doc(`${customerId}`)
      .onSnapshot({
        next: docSnap => {
          console.log({ ...docSnap.data() })
          setCustomer({ id: docSnap.id, ...docSnap.data() })
          setIsLoading(false)
        },
        error: e => {
          const { message } = e
          if (message && message.trim().slice(0, 10) === "false for ") {
            setError("db-permission-denied")
          } else {
            console.error(e)
            setError(true)
          }
          setIsLoading(false)
        },
        complete: () => {
          // setIsLoading(false);
        },
      })
    return unsubscribe
  }, [firebase, shop, setCustomer, customerId])
  useEffect(() => {
    const breadcrumb = {
      label: "Customers",
      path: "/app/customers",
      onAction: () => history.dispatch(History.Action.PUSH, '/app/customers')
    }
    // return () => {
    if (customer) {
      setTitleBar(
        context,
        `${customer.firstName} ${customer.lastName}`,
        breadcrumb
      )
    } else {
      // setTitleBar(context, title, breadcrumb)
    }
    // }
  }, [customer])
  if (!customer || isLoading) return loadingTemplate({})
  if (!isLoading && typeof customer.customerCreatedAt === 'undefined') { return <ErrorBanner errorCode='record-not-found' /> }
  return (
    <Page
      breadcrumbs={[{
        content: "Customers",
        // url: "/app/customers",
        onAction: () => {
          history.dispatch(History.Action.PUSH, '/app/customers');
          navigate('/app/customers')
        }
      }]}
      title={`${customer.firstName} ${customer.lastName}`}
      titleMetadata={[
        <Badge key={customer.state} status={customer.state === "enabled" ? "success" : "new"}>
          {customer.state === "enabled" ? "Member" : "Visitor"}
        </Badge>,
      ]
      }
      secondaryActions={[
        {
          icon: EditMinor,
          content: "Adjust points",
          onAction: () => {
            setShowPointModal(true)
          },
        },
      ]}
      actionGroups={[
        {
          title: "More actions",
          actions: [
            {
              content: "Shopify Customer",
              icon: CustomersMajorMonotone,
              onAction: () => {
                redirect.dispatch(Redirect.Action.ADMIN_SECTION, {
                  name: Redirect.ResourceType.Customer,
                  resource: {
                    id: customer.id,
                  },
                })
              },
            },
          ],
        },
      ]}
    >
      <Layout>
        {/* <Layout.Section>
            <PrettyPrintJson data={gqlData} />
        </Layout.Section> */}
        <Layout.Section>
          <Banner
            title="Marketing messages"
            status="warning"
          >
            <p>This customer has opted out of recieving marking messages.</p>
          </Banner>
          <ErrorBanner errorCode={error} />
          {/* <PermissionRedirectModal active={!!permissionNeededUrl} url={`${permissionNeededUrl}`} /> */}
        </Layout.Section>
        <Layout.Section>
          {customer ? (
            <div>
              <PointsModal
                active={showPointModal}
                customer={customer}
                onDismiss={data => {
                  const toastNotice = Toast.create(context, {
                    message: "Customer updated",
                    duration: 5000,
                  })
                  const errorNotice = Toast.create(context, {
                    message: `Unable to adjust`,
                    duration: 8000,
                    isError: true,
                  })
                  if (data && data.save) {
                    const { message, newPoints } = data
                    if (message && newPoints) {
                      adjustCustomerPoints({
                        firebase,
                        shop,
                        message,
                        newPoints,
                        customerId: customer.id,
                      }).then(
                        () => {
                          toastNotice.dispatch(Toast.Action.SHOW)
                          setShowPointModal(false)
                        },
                        () => {
                          errorNotice.dispatch(Toast.Action.SHOW)
                        }
                      )
                    } else {
                      errorNotice.dispatch(Toast.Action.SHOW)
                      setShowPointModal(false)
                    }
                  } else {
                    setShowPointModal(false)
                    // Message was not saved
                    // Do we have a url?
                    // if (data.url) {

                    // }
                  }

                }}
              />
              <CustomerCard customer={customer} />
              <AvailableRewarads
                shop={shop}
                firebase={firebase}
                token={token}
                customerId={customer.id}
              />
              <MessageCard
                // prefix="DaKine: "
                // suffix=" Reply HELP for help, STOP cancel"
                prefix={prefix}
                suffix={suffix}
                maxLength={(maxLength - suffix.length - prefix.length)}
                onAction={(data) => {
                  console.log({ data })
                  // const {message} = data;
                  // if ( message ) {
                  //   console.log(`Sending message ${message}`);
                  //   callSendMessage({message}).then((result) => {
                  //     console.log(`Result:`, result)
                  //   }, (e) => {
                  //     console.error(`Error sending`, e);
                  //   });
                  // }
                }} />
                <ClaimedRewards
                  firebase={firebase}
                  shop={shop}
                  customerId={customerId}
                 />

            </div>
          ) : (
              <p>No customer</p>
            )}
        </Layout.Section>
        <Layout.Section>
          <FooterHelp>
            Learn more about{" "}
            <Link
              onClick={() => {
                console.error("Customers Link")
              }}
            >
              customers
            </Link>
          </FooterHelp>
        </Layout.Section>
      </Layout>
    </Page>
  )
}



export default withFirebase(CustomerDetail)
