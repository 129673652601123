import { TitleBar, History, Toast, Button, Redirect } from "@shopify/app-bridge/actions"
// import { useContext } from "react";
// import { Context } from "@shopify/app-bridge-react"
import { ClientApplication } from "@shopify/app-bridge";
import { navigate } from "gatsby";
// import { useContext, useEffect, useState } from 'react'
// import { Context as ShopifyContext } from '@shopify/app-bridge-react'

export const setTitleBar = (app: ClientApplication<any>, title: string, breadcrumb?: {
    label: string,
    path: string
}) => {
    let breadcrumbButton: Button.Button | undefined = undefined;
    if (breadcrumb) {
        breadcrumbButton = Button.create(app, { label: breadcrumb?.label })
        breadcrumbButton.subscribe(Button.Action.CLICK, () => {
            console.log(`Redirect to ${breadcrumb.path}`);
            // app.dispatch(Redirect.toApp({ path: breadcrumb.path }));
            History.create(app).dispatch(History.Action.PUSH, breadcrumb.path);
            navigate(breadcrumb.path);
        });
    } 
    return TitleBar.create(app, { title, breadcrumbs: breadcrumbButton })
}