import React, { useState, useContext, useEffect } from "react";
import { SkeletonPage, Layout, Card, SkeletonBodyText, TextContainer, SkeletonDisplayText, Spinner } from "@shopify/polaris";

const template:React.FC<{
    title?: string;
}> = ({title}) => (
<SkeletonPage title={title} secondaryActions={2}>
    <Layout>
      <Layout.Section>
        <Card sectioned>
          <div style={{ textAlign: "center" }}>
            <Spinner
              accessibilityLabel="Loading content"
              size="large"
              color="teal"
            />
          </div>
        </Card>
      </Layout.Section>
    </Layout>
</SkeletonPage>
)
export default template;
