import React from "react"
import { Customer } from "../../helpers/types"
import {
  Card,
  Layout,
  TextStyle,
  TextContainer,
  Heading,
  Link as PolarisLink,
} from "@shopify/polaris"

import "./styles.css"

const component: React.FC<{ customer?: Customer }> = props => {
  const { customer } = props
  if (!customer) return null

  return (
    <Card>
      <Card.Section>{customer.firstName}</Card.Section>

      <Card.Section>
        <div className="container">
          <div className="item">
            <TextContainer>
              <p>
                <TextStyle variation="subdued">Total Points</TextStyle>
              </p>
              <Heading>{customer.points || 0}</Heading>
              <p>
                <TextStyle variation="subdued">
                  {customer.ordersCount} order{customer.ordersCount === 1 ? '' : 's'}
                </TextStyle>
              </p>
            </TextContainer>
          </div>
          <div className="item">
            <TextContainer>
              <TextStyle variation="subdued">
                <PolarisLink url="Test">Last Order</PolarisLink>
              </TextStyle>
              <Heading>Time</Heading>
              <p>
                <TextStyle variation="subdued">From: Draft Orders</TextStyle>
              </p>
            </TextContainer>
          </div>
          {/* <div className="item">Three</div> */}
        </div>
      </Card.Section>
    </Card>
  )
}

export default component
