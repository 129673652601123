import React, { useState, useContext, useEffect, useCallback } from "react";
import { Card, FormLayout, TextField, Select, Checkbox, TextContainer, TextStyle, Subheading } from "@shopify/polaris";
import * as firebase from "firebase/app";

const suffixText = 'Reply STOP...'

const component: React.FC<{
    message?: string,
    prefix?: string,
    suffix?: string,
    maxLength?: number,
    verifyMarketing?: boolean,
    onAction?: (data: { message?: string }) => void
}> = props => {
    const defaultMaxLength = 160;
    const {suffix, prefix, maxLength} = props;
    const [reason, setReason] = useState('test');
    const [message, setMessage] = useState('');
    const [marketing, setMarketing] = useState(false);
    const [sending, setSending] = useState(false);
    // const [preview, setPreview] = useState('');
    // const prefix = "DaKine: "
    // const suffix = " Reply HELP for help, STOP cancel"

    // Should probably validate;
    const handleMessageChange = useCallback((newMessage) => {
        // setPreview(`${prefix}${newMessage}${suffix}`);
        return setMessage(newMessage);
    }, [prefix, suffix]);
    const handleMarketingChange = useCallback((newChecked) => setMarketing(newChecked), []);
    const handleReasonChange = useCallback((value) => setReason(value), []);
    const handleSend = async () => {
        //   handleModalChange();
        setSending(true);
        if (props.onAction) {
            props.onAction({
                message,
            })
            await callSendMessage({ message })
        }
        setSending(false);

    }

    const options = [
        { label: 'Testng', value: 'test' },
        { label: 'Point adjustment', value: 'adjustment' },
        { label: 'Promotion', value: 'promition' },
    ];

    const messageLengthValid = message.length > 0 && message.length <= (maxLength || defaultMaxLength);

    return (
        <Card
            title="Send text message"
            primaryFooterAction={{
                content: 'Send message',
                onAction: handleSend,
                loading: sending,
                disabled: !marketing || !messageLengthValid || sending
            }}
        >
            <Card.Section>

            <FormLayout>
                <TextField
                    label="Message"
                    placeholder="Text content to be sent"
                    // prefix="DaKine: "
                    // suffix="Reply HELP for help, STOP cancel."
                    onChange={handleMessageChange}
                    autoComplete={false}
                    // helpText={`${preview.length}/${maxLength}`}
                    value={message}
                    showCharacterCount={true}
                    maxLength={maxLength || defaultMaxLength}
                />
                <Select
                    label="Reason"
                    options={options}
                    onChange={handleReasonChange}
                    value={reason}
                />
            </FormLayout>
            </Card.Section>
            <Card.Section title="Preview" subdued >
                { message ? <>
                    <TextContainer>
                        <p>
                        <TextStyle variation="subdued">{prefix}</TextStyle>
                        <TextStyle variation="strong">{message}</TextStyle>
                        <TextStyle variation="subdued">{suffix}</TextStyle>
                        </p>
                    </TextContainer>
                    <Checkbox
                        label="This is not marketing content."
                        checked={marketing}
                        onChange={handleMarketingChange}
                    />
                    </>
                : <p>Type a message to see a preview</p>}
            </Card.Section>
        </Card>
    )
};

const callSendMessage = (params: {
    message: string,
    reason?: string,
    confirmationToken?: string
}) => {
    return firebase.functions().httpsCallable('callSendMessage')(params)
}

export default component;
