import { useState, useCallback } from "react";

export const useQueryString = (key: string) => {
    const getQueryParamValue = (key: string) => {
        return new URLSearchParams(window.location.search).get(key);
    }
    const updateQueryStringWithoutReload = (queryString: string) => {
        const { protocol, host, pathname } = window.location;
        const newUrl = `${protocol}//${host}${pathname}?${queryString}`;
        window.history.pushState({ path: newUrl }, '', newUrl);
    }
    const [ paramValue, setParamValue ] = useState(getQueryParamValue(key));
    const onSetValue = useCallback(
        newValue => {
            setParamValue(newValue);
            updateQueryStringWithoutReload(newValue ? `${key}=${newValue}` : '');
        },
        [key, setParamValue]
    );
    return [paramValue, onSetValue];
}