import React, { useState, useCallback, useEffect } from "react"
import { Button, Modal, Stack, ChoiceList, TextField } from "@shopify/polaris"
import { Customer } from "../../helpers/types"

const modal: React.FC<{
  active?: boolean
  customer?: Customer
  onDismiss?: (data?: {
    previousPoints?: number
    newPoints?: number
    message?: string
    save: boolean
  }) => void
}> = props => {
  // const {active: activeInput } = props
  const [modalActive, setModelActive] = useState(false)
  const [customer, setCustomer] = useState(props.customer)
  const [existingPoints, setExistingPoints] = useState(0)
  const [newPoints, setNewPoints] = useState(0)
  const [message, setMessage] = useState("")

  useEffect(() => {
    setModelActive(props.active || false)
    setCustomer(props.customer)
    setMessage("");
    if (props.customer) {
      setNewPoints(props.customer.points || 0);
      setExistingPoints(props.customer.points || 0);
    }
  }, [props.customer, props.active])


  const handleClose = (save = false) => () => {
    //   handleModalChange();
    if (props.onDismiss) {
      props.onDismiss({
        previousPoints: existingPoints,
        newPoints,
        message,
        save,
      })
    }
  }

  return (
    <div>
      <Modal
        open={modalActive}
        onClose={handleClose()}
        title="Adjust points"
        primaryAction={{
          content: "Adjust points",
          onAction: handleClose(true),
          disabled: !(message.length > 0 && newPoints > 0)
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleClose(),
          },
        ]}
      >
        <Modal.Section>
          <TextField
            label="Points"
            type="number"
            value={'' + newPoints}
            onChange={(data) => setNewPoints(parseInt(data))}
          />
          <TextField
            label="Notes"
            value={message}
            onChange={setMessage}
            helpText="Adding a note helps understand why the adjustment was needed."
            multiline={2}
          />
        </Modal.Section>
      </Modal>
    </div>
  )
}

export default modal
