import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Card, ResourceList, Avatar, ResourceItem, Stack, TextStyle, TextContainer } from "@shopify/polaris"
import { getCustomersClaimedRewards } from '../../helpers/database';
import { PrettyPrintJson } from '../PrettyPrint';
import Moment from 'react-moment';

export const ClaimedRewards: React.FC<{
    shop: string,
    customerId: string;
    firebase: firebase.app.App
}> = ({ shop, customerId, firebase }) => {

    const [claimedRewards, setClaimedRewards] = useState([{}] as any[])
    const rewardsRef = getCustomersClaimedRewards(firebase, shop, customerId);
    const [query, setQuery] = useState(
        rewardsRef
            .orderBy("createdAt", "asc")
    )
    useEffect(() => {
        const unsubscribe = query.onSnapshot({
            next: querySnap => {
                const c = querySnap.docs.map(docSnap => ({
                    id: docSnap.id,
                    ...docSnap.data(),
                }))
                setClaimedRewards(c);
                console.log(c);
                // setIsLoading(false);
            },
            error: e => {
                console.error(e)
                const { message } = e
                if (message && message.trim().slice(0, 10) === "false for ") {
                    //   setError("db-permission-denied")
                } else {
                    //   setError(true)
                }
                // setIsLoading(false)
            }
        })
        return unsubscribe
    }, [query])
    return (
        <Card title="Claimed rewards">
            {/* <PrettyPrintJson data={claimedRewards} /> */}
            {claimedRewards.length > 0 ? 
            <ResourceList
                resourceName={{ singular: 'reward', plural: 'rewards' }}
                items={claimedRewards}
                renderItem={(item) => {
                    const { id, rewardName, pointsDeducted, ruleCreatedAt, code, rewardId } = item;
                    const media = <Avatar customer={false} size="medium" name={name} />
                    const url = `/app/rewards/edit?id=${rewardId}`
                    const detailUrl = url;
                    const createdAt = ruleCreatedAt && typeof ruleCreatedAt.toDate === 'function' ? ruleCreatedAt.toDate() : new Date(ruleCreatedAt);

                    return (
                        <ResourceItem
                            id={id}
                            onClick={() => {
                                console.log('clicked');
                            }}
                            // url={url}
                            media={media}
                            accessibilityLabel={`Open reward dialog for ${rewardName}`}
                            shortcutActions={[{
                                content: 'View reward detail',
                                url: detailUrl
                            }]}
                        >
                            <Stack distribution="fill" alignment="center">
                                <TextContainer>

                                    <h3>
                                        <TextStyle variation="strong">{rewardName}</TextStyle>
                                    </h3>
                                    <TextStyle variation="subdued">Created <Moment fromNow date={createdAt} /></TextStyle>
                                </TextContainer>
                                <div>{pointsDeducted} points</div>
                                <TextContainer>Code: <TextStyle variation="code">{code}</TextStyle></TextContainer>
                            </Stack>
                        </ResourceItem>
                    );
                }}
            />
            : <Card.Section>No rewards claimed yet</Card.Section>}
        </Card>

    )
}
